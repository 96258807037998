.popup-hidden {
  display: none;
}


.popup-write {
  position: relative;
  margin: 0 auto;
  max-width: 677px;
  padding-top: 45px;
  padding-left: 82px;
  padding: 45px 82px 55px 82px;
  background: #e9e9e9;

  &__top:after {
    content: '';
    display: block;
    clear: both;
  }

  &__ask {
    float: left;
  }

  &__logo {
    float: right;
  }

  &__ask {
    color: #c11d1d;
    font-size: 13px;
    font-family: 'SFUIDisplay-Heavy';
    line-height: 14.72px;
    text-transform: uppercase;
    text-transform: uppercase;
    padding-bottom: 33px;
  }

  &__logo {
    display: block;

  }
}

.popup-form {
  &__wrap {
    display: flex;
    justify-content: space-between;
  }

  &__text {
    color: #1e1f1f;

    &:nth-child(2) {
      padding-bottom: 25px;
    }
  }

  &__email,
  &__text,
  &__location {
    color: #1e1f1f;
    padding-left: 30px;
    position: relative;

    img {
      position: absolute;
      left: 0;
      top: 0;
      width: 20px;
    }
  }

  &__text {
    img {
      display: none;
    }
  }

  &__email,
  &__location {
    display: none;
  }

  &__email {
    padding-bottom: 30px;
  }

  &__location {
    color: #1e1f1f;
  }

  &__input,
  &__message {
    width: 100%;
    margin-bottom: 27px;
  }

  &__input {
    margin-bottom: 23px;
    width: 100%;
  }

  &__send {
    padding-left: 40px;
    padding-right: 40px;
  }
}

@media screen and (max-width:1024px) {
  .popup-write {
    padding-bottom: 200px;

    &__top {}

    &__ask {
      float: right;
    }

    &__logo {
      float: left;
    }
  }

  .popup-form {
    &__wrap {
      flex-direction: column;
    }

    &__text {
      img {
        display: block;
      }
    }

    &__email,
    &__location {
      display: block;
    }

    &__send {
      margin-bottom: 60px;
    }

  }
}

@media screen and (max-width:700px) {
  .popup-write {
    padding-left: 30px;
    padding-right: 30px;

  }
}

@media screen and (max-width:400px) {
  .popup-write {
    padding-bottom: 200px;
    width: 100%;

    &__top {}

    &__ask,
    &__logo {
      float: none;
    }

    &__logo {
      margin-bottom: 30px;
    }

    .popup-form__text {}
  }
}