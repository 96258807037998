.about-studio-section {}

.about-studio-container {
  max-width: 1120px;
  margin: 0 auto;
  padding: 0 70px;

}

.about-studio-wrap {}

.about-studio-top {
  min-height: 687px;
  background: #202121 url('../img/about-first-bg.png') no-repeat center bottom/cover;
  padding-top: 147px;
  padding-bottom: 38px;
  position: relative;

  &>* {
    position: relative;
    z-index: 2;
  }

  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, .83);
  }

  &__our-clients {
    color: #08b06e;
    font-size: 19px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 1.93px;

    span {
      display: block;

    }
  }
}

.about-studio-first {

  display: flex;
  justify-content: space-between;


  &__item {
    width: 47%;
  }

  &__quest {
    font-size: 19px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 1.93px;
    margin-bottom: 22px;

    span {
      display: block;


      &:nth-child(1) {
        color: #c1001f;
      }

      &:nth-child(2) {
        color: #08b06e;
      }
    }
  }

  &__desc {
    color: #ffffff;
    font-size: 19px;
    font-weight: 300;
    line-height: 24.12px;
    letter-spacing: 0.96px;
    margin-bottom: 185px;
  }
}

.about-studio-bottom {

  background: #171919;
}

.about-studio__last-container {
  margin: 0 auto;
  max-width: 1720px;
  padding: 0 30px;

}

.about-studio-last {
  padding-top: 110px;
  padding-bottom: 110px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 0 -15px;


  &__item {
    margin-bottom: 30px;
    text-align: center;

    img {

      max-width: 100%;

    }

    margin-right:15px;
    margin-left:15px;

    width: calc(100% / 12 * 2 - 30px);
  }

}