/*1 экран*/
/*страница контакты*/



/*aфутер*/
@media screen and (max-width:1720px) {
  .footer-container {
    max-width: 1400px;
    margin: 0 auto;
  }
}

@media screen and (max-width:1540px) {
  .footer-container {
    padding: 0 100px;
  }
}

@media screen and (max-width:700px) {
  .footer-container {
    padding: 0 30px;
  }

  .footer__content {
    flex-direction: column;
  }

  .footer__icons {
    padding-top: 17px;
  }
}

/*блок слайдер*/
@media screen and (max-width:1860px) {
  .slider-section {
    height: 870px;
  }
}

@media screen and (max-width:1760px) {
  .slider-section {
    height: 810px;
  }
}

@media screen and (max-width:1660px) {
  .slider-section {
    height: 760px;
  }
}

/*страница о студии картинки*/
@media screen and (max-width:1600px) {
  .about-studio-last {

    &__item {
      width: calc(100% / 12 * 3 - 30px);
    }

  }
}

@media screen and (max-width:1560px) {
  .slider-section {
    height: 710px;
  }
}

@media screen and (max-width:1460px) {
  .slider-section {
    height: 660px;
  }
}

@media screen and (max-width:1360px) {
  .slider-section {}
}


/*блок портфолио*/

@media screen and (max-width:1620px) {
  .portfolio {
    &__item {
      min-height: 380px;
    }
  }
}

@media screen and (max-width:1455px) {
  .portfolio {
    &__item {
      min-height: 340px;
    }
  }
}

@media screen and (max-width:1320px) {
  .portfolio {
    &__item {
      min-height: 320px;
    }
  }
}

@media screen and (max-width:1107px) {
  .portfolio {
    &__item {
      min-height: 300px;
    }
  }
}

/*страница о студии картинки*/
@media screen and (max-width:1100px) {
  .about-studio-last {

    &__item {
      width: calc(100% / 12 * 4 - 30px);
    }

  }
}

@media screen and (max-width:1205px) {
  .portfolio {
    &__item {
      min-height: 280px;
    }
  }
}



/*блок красной навигации*/


/*основные уровни*/

/*Ipad Pro*/
@media screen and (max-width:1024px) {

  /*страница контакты*/
  .contacts-wrap {
    flex-direction: column;
    padding: 0 100px;
  }

  .form__send {
    margin-bottom: 60px;
  }

  .contacts__titling {
    margin-bottom: 40px;
  }

  .contacts-section {
    padding-bottom: 120px;
  }

  .footer__content {
    padding: 0;
  }

  .feedback {
    width: 100%;
  }

  .contacts {
    width: 100%;
  }

  /*страница о студии картинки*/
  .about-studio-top {
    padding-top: 60px;
  }

  .about-studio-first {
    flex-direction: column;

    &__item {
      width: 100%;
    }

    &__desc {
      margin-bottom: 90px;
    }
  }

  .about-studio__last-container {
    padding: 0 145px;
  }

  .about-studio-container {
    padding: 0 145px;
  }

  .about-studio-last {
    padding-top: 70px;
    padding-bottom: 0px;

    &__item {
      width: calc(100% / 12 * 6 - 60px);
      margin-right: 30px;
      margin-left: 30px;
      text-align: center;
      padding-bottom: 60px;

    }
  }



  /*блок слайдер*/
  .slide {
    display: none !important;
  }

  /*блок kabidoo-дизайн студия*/
  .about {
    &-desc {
      width: 55%;
    }
  }


  /*блок портфолио*/
  .portfolio {
    grid-template-columns: repeat(2, 1fr);

    &__item {
      min-height: 480px;

      &:nth-child(3) {
        grid-row: 1/2;
        grid-column: 1/2;
      }

      &:nth-child(4) {
        grid-row: 1/2;
        grid-column: 2/3;
      }

      &:nth-child(7) {
        grid-row: 2/3;
        grid-column: 1/2;
      }

      &:nth-child(8) {
        grid-row: 2/3;
        grid-column: 2/3;
      }

      &:nth-child(10) {
        grid-row: 3/4;
        grid-column: 1/3;
      }

      &:nth-child(14) {
        grid-row: 4/5;
        grid-column: 1/2;
      }

      &:nth-child(13) {
        grid-row: 4/5;
        grid-column: 2/3;
      }

      &:nth-child(1) {
        grid-row: 5/6;
        grid-column: 1/2;
      }

      &:nth-child(5) {
        grid-row: 6/8;
        grid-column: 1/2;
      }
    }

  }

  /*блок красной навигации*/
  .navigation {
    &__wrap {
      width: 100%;
    }

    &__filter-box {
      display: block;
      padding: 16px 25px;

    }

    &__filter {

      color: #ffffff;
      font-weight: 400;
      letter-spacing: 0.37px;
    }
  }

  .navbar {
    flex-direction: column;
    background-color: #262627;
    width: 100%;

  }

  /*конец блока красной навигации*/

  /*блок skills*/
  .skills {
    &__row {
      flex-direction: column;
    }

    &__item {
      font-size: 18px;
      font-weight: 300;
      line-height: 28.4px;
      width: 80%;
      margin-bottom: 40px;

    }
  }

  /*блок с зеленой и красной кнопками*/
  .download {
    flex-direction: column;

    &__item {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      letter-spacing: 0.72px;
    }

    &__presentation {
      background: #5ce38f;
    }

    &__writetous {
      background: #c11d1d;
    }
  }
}

@media screen and (max-width:920px) {
  .about-studio__last-container {
    padding: 0 100px;
  }

  .about-studio-container {
    padding: 0 100px;
  }

  .about-studio-last {
    &__item {
      width: calc(100% / 12 * 6 - 30px);
      margin-right: 15px;
      margin-left: 15px;

    }
  }
}

@media screen and (max-width:780px) {
  .about-studio__last-container {
    padding: 0 100px;
  }

  .about-studio-container {
    padding: 0 100px;
  }

  .about-studio-last {
    &__item {
      width: calc(100% / 12 * 6 - 30px);
      margin-right: 15px;
      margin-left: 15px;

    }
  }
}

@media screen and (max-width:700px) {

  /*блок skills*/
  .skills {
    padding-top: 50px;
    padding-bottom: 80px;

    &__item {
      width: 70%;
      margin-bottom: 20px;
    }
  }

  /*страница контакты*/
  .contacts-wrap {
    flex-direction: column;
    padding: 0 30px;
  }

  .about-studio__last-container {
    padding: 0 30px;
  }

  .about-studio-container {
    padding: 0 30px;
  }

  .about-studio-last {
    &__item {
      width: calc(100% / 12 * 6 - 30px);
      margin-right: 15px;
      margin-left: 15px;

    }
  }
}

@media screen and (max-width:480px) {
  .about-studio__last-container {
    padding: 0 30px;
  }

  .about-studio-container {
    padding: 0 30px;
  }

  .about-studio-last {
    &__item {
      width: calc(100% / 12 * 12 - 30px);
      margin-right: 15px;
      margin-left: 15px;
      text-align: left;


    }
  }
}


/*блок портфолио*/

@media screen and (max-width:940px) {
  .portfolio {
    &__item {
      min-height: 440px;
    }
  }
}

@media screen and (max-width:885px) {
  .portfolio {
    &__item {
      min-height: 400px;
    }
  }
}

@media screen and (max-width:830px) {
  .portfolio {
    &__item {
      min-height: 390px;
    }
  }
}

/*@media screen and (max-width:800px) {

  .about-studio-last {

    &__item {
      width: calc(100% / 12 * 12 - 30px);
      margin-bottom: 100px;

      &:nth-child(1n),
      &:nth-child(2n) {
        text-align: left;
      }
    }

  }

  .about-studio__last-container {
    padding: 0 30px;
  }

  .about-studio-container {
    padding: 0 30px;
  }


}*/

/*Ipad*/
@media screen and (max-width:768px) {

  /*блок kabidoo-дизайн студия*/
  .about {
    &-desc {
      width: 75%;
    }
  }

}

/*блок портфолио*/
@media screen and (max-width:755px) {
  .portfolio {
    &__item {
      min-height: 360px;
    }
  }
}

/*блок портфолио*/
@media screen and (max-width:700px) {
  .portfolio {
    &__item {
      min-height: 310px;
    }
  }



}

/*блок портфолио*/
@media screen and (max-width:600px) {
  .portfolio {
    &__item {
      min-height: 280px;
    }
  }
}

/*блок портфолио*/
@media screen and (max-width:515px) {
  .portfolio {
    &__item {
      min-height: 250px;
    }
  }
}

/*блок портфолио*/
@media screen and (max-width:450px) {
  .portfolio {
    &__item {
      min-height: 215px;
    }
  }
}

/*блок портфолио*/
@media screen and (max-width:407px) {
  .portfolio {
    &__item {
      min-height: 190px;
    }
  }

  /*блок kabidoo-дизайн студия*/
  .btn {
    width: 220px;
  }
}

/*блок портфолио*/
@media screen and (max-width:370px) {
  .portfolio {
    &__item {
      min-height: 170px;
    }
  }
}

/*блок портфолио*/
@media screen and (max-width:330px) {
  .portfolio {
    &__item {
      min-height: 160px;
    }
  }
}

/*блок портфолио*/
@media screen and (max-width:580px) {
  .portfolio {
    &__item {}
  }
}

/*блок портфолио*/
@media screen and (max-width:512px) {
  .portfolio {
    &__item {}
  }
}

/*Iphone 6,7,8 Plus*/
@media screen and (max-width:414px) {

  /*блок kabidoo-дизайн студия*/
  .about {
    &-desc {
      width: 100%;
    }

    padding-left:15px;
    padding-right: 15px;
  }

}

/*Iphone X*/
@media screen and (max-width:375px) {}

/*Iphone 5S*/
@media screen and (max-width:320px) {}