.head {
  background: #e9e9e9;

  &-wrap {
    max-width: 1680px;

    margin: 0 auto;


  }
}


.header__nav-item_active {
  position: relative;
}

.header__nav-item_active:after {
  position: absolute;
  content: url(../img/header-item-active.png);

  bottom: -35px;

}

.header {
  min-height: 84px;
  display: flex;
  justify-content: space-between;
  align-items: center;


  .header__burg {
    display: none;
    width: 26px;
    height: 22px;
    margin-left: 20px;
    position: relative;
    cursor: pointer;

    &-bar {
      display: block;
      width: 100%;
      height: 4.1px;
      background-color: #e22a36;
      border-radius: 9px;
      position: absolute;
      left: 0;
      transform: rotate(0deg);
      transition: .25s ease;

      &:nth-child(1) {
        top: 0px;
      }

      &:nth-child(2) {
        top: 9px;
      }

      &:nth-child(3) {
        top: 9px;
      }

      &:nth-child(4) {
        top: 18px;
      }
    }
  }


  &__menu {

    display: flex;
    justify-content: space-between;
    width: 47%;

  }

  &__nav {
    display: flex;

    margin-right: 17%;
  }

  &__nav-item {
    margin-right: 30px;
    color: #241414;
    font-size: 13px;
    line-height: 25.55px;
    text-transform: uppercase;

    &_active {
      font-family: "SFUIDisplay-Heavy"; //на bold
      font-weight: 700;
      letter-spacing: 1.2px;
    }

    &:nth-last-child(1) {
      margin-right: 0;
    }


  }

  &__nav-img_active {
    position: absolute;
    bottom: 0;
  }

  &__nav-link {
    display: block;
  }

  &__contacts {
    margin-right: 12%;
  }

  &__contacts-item {
    font-size: 14px;
    font-weight: 300;
    line-height: 15.42px;
  }

  &__phone {
    color: #2a2a2a;
    letter-spacing: 0.96px;
  }

  &__email {
    color: #5ce38f;
    letter-spacing: 0.96px;
    margin-left: 9px;
  }

  &__socials {
    display: flex;
    align-items: center;
    margin-right: 4%;
  }

  &__socials-item {
    margin-right: 9px;

    &:nth-last-child(1) {
      margin-right: 0;
    }
  }

  &__socials-link {
    i {
      font-size: 20px;
      color: #5ce38f;
    }
  }

  &__langs {
    align-items: center;
    display: flex;
  }

  &__langs-item {
    margin-right: 6px;
    color: #191919;
    font-size: 13px;
    font-weight: 700;
    line-height: 25.55px;
    text-transform: uppercase;
    letter-spacing: 1.2px;

    &_active {
      color: #aaaaaa;
    }

    &:nth-last-child(1) {
      margin-right: 0;

    }
  }
}



/*adaptive*/
@media screen and (max-width:1922px) {
  .head-wrap {
    width: 90%;
  }

  .header__menu {
    width: 48%;
  }

  .header__nav {
    margin-right: 16%;
  }

  .header__contacts {
    margin-right: 10%;
  }

  .header__socials {
    margin-right: 3%;
  }
}

@media screen and (max-width:1640px) {
  .head-wrap {}

  .header__menu {
    width: 50%;
  }

  .header__nav {}

  .header__contacts {
    margin-right: 9%;
  }

  .header__socials {}
}

@media screen and (max-width:1560px) {
  .head-wrap {}

  .header__nav {
    margin-right: 12%;
  }

  .header__menu {
    width: 53%;
  }
}

@media screen and (max-width:1400px) {
  .head-wrap {}

  .header__menu {
    width: 56%;
  }

  .header__nav {
    margin-right: 10%;
  }

  .header__contacts {
    margin-right: 6%;
  }
}

@media screen and (max-width:1250px) {
  .head-wrap {}

  .header__menu {
    width: 67%;
  }

  .header__nav {
    margin-right: 3%;
  }

  .header__contacts {
    margin-right: 2%;
  }

  .header__socials {
    margin-right: 0;
  }
}

@media screen and (max-width:1024px) {
  .header__nav-item_active:after {
    position: absolute;
    content: '';

    bottom: -35px;

  }

  .header {
    flex-direction: column;
  }

  .head-wrap {
    width: 100%;

  }

  .header__menu {

    flex-direction: column;
    width: 100%;
    display: none;
  }

  .header__nav-item {
    margin-bottom: 60px;
    font-size: 25px;
    letter-spacing: 0.6px;
    font-weight: 400;

    &:nth-last-child(1) {
      margin-bottom: 0;
    }
  }


  .header__logo {
    min-height: 216px;
    display: flex;
    align-items: center;
  }

  .header__logo-img {
    display: block;
    max-width: 100%;
  }

  .header__nav {
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .header__nav-item {
    margin-right: 0;
  }

  .header__first {
    width: 100%;
    border-bottom: 3px solid #d2d2d2;
    padding: 0 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;

  }




  .header__contacts {
    display: none;
  }

  .header__langs {
    display: none;
  }

  .header__socials {
    margin-right: 0;
    width: 100%;
    border-top: 3px solid #d2d2d2;
    display: flex;
    justify-content: center;
    padding: 50px 0;
    align-items: center;
  }

  .header__nav-img_active {
    display: none;
  }

  .header__socials-link i {
    font-size: 38px;
  }

  .header__burg {
    display: block !important;
  }

  .header__burg_opened {


    .header__burg-bar {
      display: block;


      &:nth-child(1) {
        top: 18px;
        width: 0;
        left: 50%;

      }

      &:nth-child(2) {
        transform: rotate(45deg);
      }

      &:nth-child(3) {
        transform: rotate(-45deg);

      }

      &:nth-child(4) {

        top: 18px;
        width: 0;
        left: 50%;

      }

    }
  }


}

@media screen and (max-width:410px) {
  .header__first {
    padding: 0 40px;
  }


}