.portfolio {
  min-height: 400px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);

  &__arrow {
    cursor: pointer;
    position: absolute;
    top: 50%;
  }

  &__item-wrap {
    background: #000;
  }

  &__item {
    min-height: 422px;
    background-repeat: no-repeat;
    background-position: center top;
    background-size: cover;
    position: relative;

    &:nth-child(5) {
      grid-column: 1;
      grid-row: 2/4;
    }

    &:nth-child(10) {
      grid-column-start: 3;
      grid-column-end: -1;
    }
  }

  &__blockText {
    padding-top: 52px;

    p {
      font-size: 19px;
      color: #ffffff;

      &:nth-child(1) {
        font-weight: 700;
        text-transform: uppercase;
        letter-spacing: 1.2px;
      }

      &:nth-child(2) {
        font-size: 19px;
        font-weight: 400;
        line-height: 19.53px;
        letter-spacing: 0.24px;
        line-height: 32px;
      }
    }
  }

  &__bottomText {
    color: #ffffff;
    font-size: 11px;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 1.2px;
    position: absolute;
    bottom: 45px;
  }
}

.portfolio__desc {
  background: #000;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, .5);
  opacity: 0;
  transition: all .35s;
  padding-left: 55px;
}

.portfolio__item:hover .portfolio__desc {
  opacity: 1;
}