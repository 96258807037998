@font-face {
  font-family: 'Akrobat';
  src: url('../../fonts/akrobat/Akrobat-Regular.eot');
  src: url('../../fonts/akrobat/Akrobat-Regular.eot?#iefix') format('embedded-opentype'),
    url('../../fonts/akrobat/Akrobat-Regular.woff') format('woff'),
    url('../../fonts/akrobat/Akrobat-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Franklin';
  src: url('../../fonts/franklin/ITCFranklinGothicStd-BkCd.eot');
  src: url('../../fonts/franklin/ITCFranklinGothicStd-BkCd.eot?#iefix') format('embedded-opentype'),
    url('../../fonts/franklin/ITCFranklinGothicStd-BkCd.woff') format('woff'),
    url('../../fonts/franklin/ITCFranklinGothicStd-BkCd.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham-Pro';
  src: url('../fonts/gotham/GothamPro-Black.eot');
  src: url('../fonts/gotham/GothamPro-Black.eot?#iefix') format('embedded-opentype'),
    url('../fonts/gotham/GothamPro-Black.woff') format('woff'),
    url('../fonts/gotham/GothamPro-Black.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'SFUIDisplay-Heavy';
  src: url('../fonts/SanFrancisco/SFUIDisplay-Heavy.eot');
  src: url('../fonts/SanFrancisco/SFUIDisplay-Heavy.eot?#iefix') format('embedded-opentype'),
    url('../fonts/SanFrancisco/SFUIDisplay-Heavy.woff') format('woff'),
    url('../fonts/SanFrancisco/SFUIDisplay-Heavy.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'SFUIDisplay-Thin';
  src: url('../fonts/SanFrancisco/SFUIDisplay-Thin.eot');
  src: url('../fonts/SanFrancisco/SFUIDisplay-Thin.eot?#iefix') format('embedded-opentype'),
    url('../fonts/SanFrancisco/SFUIDisplay-Thin.woff') format('woff'),
    url('../fonts/SanFrancisco/SFUIDisplay-Thin.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'SFUIDisplay-Regular';
  src: url('../fonts/SanFrancisco/SFUIDisplay-Regular.eot');
  src: url('../fonts/SanFrancisco/SFUIDisplay-Regular.eot?#iefix') format('embedded-opentype'),
    url('../fonts/SanFrancisco/SFUIDisplay-Regular.woff') format('woff'),
    url('../fonts/SanFrancisco/SFUIDisplay-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'SFUIDisplay-Light';
  src: url('../fonts/SanFrancisco/SFUIDisplay-Light.eot');
  src: url('../fonts/SanFrancisco/SFUIDisplay-Light.eot?#iefix') format('embedded-opentype'),
    url('../fonts/SanFrancisco/SFUIDisplay-Light.woff') format('woff'),
    url('../fonts/SanFrancisco/SFUIDisplay-Light.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}