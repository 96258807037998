.navigation {
  background: #c11d1d;
  display: flex;
  justify-content: center;

  &__filter-box {
    display: none;
  }
}

.navbar {
  display: inline-flex;



  &__item {
    padding: 16px 25px;
    color: #ffffff;
    font-size: 16px;
    font-weight: 400;

    &_active {
      background-color: #5ce38f;
    }
  }
}