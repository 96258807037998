/*slick-carouse.css*/
@import '../../node_modules/slick-carousel/slick/slick.scss';
@import '../../node_modules/slick-carousel/slick/slick-theme.scss';
@import '../../node_modules/magnific-popup/dist/magnific-popup';
/*сброс стилей*/
@import './base/discharge';
/*шрифты*/
@import './base/fonts';
/*шапка сайта*/
@import './layout/header';
/*слайдер*/
@import './layout/slider';
/*portfolio*/
@import './layout/portfolio.scss';
/*о нас*/
@import './partials/about';
/*навбар*/
@import './partials/navbar';
/*футер*/
@import './layout/footer';
/*смотреть больше*/
@import './partials/look-more';
/*skills*/
@import './partials/skills';
/*страница с контактами*/
@import './partials/contacts';
/*стили для страницы "О студии"*/
@import './partials/studio';
/*стили для страницы "Предпоказ"*/
@import './layout/popup';

@import './partials/preview';
/*адаптив*/
@import './utils/media';