.skills {
  padding-top: 82px;
  padding-bottom: 130px;
  min-height: 382px;
  background: #e9e9e9;
  border-top: 2px solid #cacaca;


  &__row {
    min-height: 200px;
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;
  }

  &__item {
    color: #424242;
    font-family: "SFUIDisplay-Light";
    font-size: 21px;
    font-weight: 300;
    line-height: 26.98px;
    width: calc(25% - 30px);
    margin: 0 15px;

  }

  &__titling {
    color: #c11d1d;
    font-family: "SFUIDisplay-Heavy";
    font-weight: 400;
    text-transform: uppercase;
  }
}

.download {
  display: flex;

  &__item {
    display: block;
    color: #def9e9 !important;
    width: 50%;
    font-size: 13px;
    font-weight: 700;
    line-height: 26.41px;
    text-transform: uppercase;
    letter-spacing: 1.2px;
    min-height: 86px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__presentation {
    background: #5ce38f;
  }

  &__writetous {
    background: #c11d1d;
  }
}