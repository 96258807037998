.footer {
  background: #141414;
  font-size: 19px;
  padding-top: 81px;
  padding-bottom: 70px;
  min-height: 395px;

  &-container {
    max-width: 1507px;
    margin: 0 auto;
  }

  &__icons {}

  &__icon {
    i {
      font-size: 20px;
      color: #5ce38f;
    }
  }

  &__right {
    display: flex;
    align-items: flex-end;
  }

  &__content {


    font-weight: 300;
    line-height: 24.01px;
    color: #efefef;
    font-family: "SFUIDisplay-Light";
    padding-bottom: 50px;
    display: flex;
    justify-content: space-between;

  }

  &__brand {
    margin-bottom: 25px;
    color: #c11d1d;
    font-family: "SFUIDisplay-Heavy";
    font-weight: 400;
    text-transform: uppercase;
  }

  &__email {
    margin-bottom: 25px;
  }

  &__copy {

    color: #3a3a3a;
  }

  &__address {
    margin-bottom: 50px;
  }
}