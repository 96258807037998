.preview {
  &__wrap {
    max-width: 1150px;
    padding: 0 30px;
    margin: 0 auto;
  }

  &__first {
    padding-bottom: 130px;
    padding-top: 98px;
    background: #eaeaea;
  }

  &-first {

    display: flex;
    justify-content: space-between;

    &__item {
      width: 43%;
      font-size: 19px;
      color: #6d6d6d;
    }

    &__red {
      color: #e34242;

    }
  }


  &__title {
    background: url('../img/preview-main-bg.jpg') no-repeat center top/cover;

    img {
      display: block;
      margin: 0 auto;
      width: 250px;
      height: auto;
    }

    &_first {
      padding: 105px 0;
    }

    &_second {
      padding: 200px 0;
    }

    &_third {
      padding: 190px 0;
    }
  }

  &__photoes {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;

    img {
      display: block;
      width: 50%;
      // height: auto;
    }
  }

  &__faces-wrap {
    padding-top: 145px;
    padding-bottom: 60px;
    background: url('../img/preview-main-bg.jpg') no-repeat center top/cover;

    img {
      display: block;
      width: 250px;
      margin: 0 auto;

    }
  }

  &__faces {

    display: flex;
    flex-flow: row nowrap;
    justify-content: space-around;
    max-width: 1200px;
    margin: 0 auto;
    padding-bottom: 80px;

    img {
      display: block;
      width: 20%;
    }
  }

  &__panel {
    height: 121px;
    background: #e9e9e9;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      display: block;
      width: 30px;
      cursor: pointer;
    }
  }

  &-slider {
    height: 342px;
    position: relative;

    &__text {
      color: #eee7e6;
      text-transform: uppercase;
      font-size: 12px;
      letter-spacing: 1.2px;

      span {
        font-family: 'SFUIDisplay-Heavy';
      }
    }

    &__item {
      height: 100%;

    }

    &__panel {
      cursor: pointer;
      width: 100%;
      height: 65px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: inherit;
      position: absolute;
      bottom: 0;
      box-shadow: 0 0 32px rgba(0, 0, 0, .2);
      background: inherit;
      background: rgba(0, 0, 0, .15);
      backdrop-filter: blur(12px);

    }
  }
}

/*Адаптив*/
@media screen and (max-width:1024px) {

  .preview {
    &-first {
      flex-direction: column;

      &__item {
        width: 70%;
        margin-bottom: 40px;

        &:nth-last-child(1) {
          margin-bottom: 0;
        }
      }
    }

    &__wrap {
      padding: 0 100px;
    }
  }
}

@media screen and (max-width:700px) {
  .preview {
    &__first {
      padding-top: 70px;
      padding-bottom: 70px;
    }

    &__faces-wrap {
      padding-top: 50px;
    }

    &__title {
      &_first {
        padding: 80px 0;

        img {
          width: 200px;
        }
      }

      &_second {
        padding: 100px 0;
      }

      &_third {
        padding: 120px 0;
      }
    }

    &-first {
      &__item {
        width: 90%;
        margin-bottom: 30px;

        &:nth-last-child(1) {
          margin-bottom: 0;
        }
      }
    }

    &__wrap {
      padding: 0 30px;
    }
  }
}

.preview__last-fragility {
  display: none !important;
}

.preview__faces {
  padding-bottom: 40px;
}