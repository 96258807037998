@import '../../../node_modules/normalize.css/normalize';

html {
  box-sizing: border-box;
}

* {
  margin: 0;
  padding: 0;
}

*,
*:after,
*:before {
  box-sizing: inherit;
}

a,
a:visited {
  color: inherit;
  text-decoration: none;
}

a:hover {
  color: inherit;
  text-decoration: none;
}

a:focus,
a:active {
  color: inherit;
  text-decoration: none;
}



ul,
li {
  list-style-type: none;
}

body,
html {
  font-family: "SFUIDisplay-Regular";
}

.general-container {
  max-width: 1531px;
  width: 90%;
  margin: 0 auto;
  padding: 0 30px;
}

.general-form {
  display: flex;
  flex-direction: column;
}

.general-input {
  border: 1px solid #5ce38f;
  display: block;
  padding: 9px 18px;

  &:active,
  &:hover,
  &:focus {
    outline: 0;
    outline-offset: 0;
  }
}

.general-send {
  color: #ffffff;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0.48px;
  max-width: 168px;
  height: 43px;
  background: #00e48b;
  border: none;
  border: none;
  outline: none;

  &:visited,
  &:active,
  &:hover,
  &:focus {
    background: #00e48b !important;
    outline: none;
  }
}

.general-message {
  resize: none;
  padding: 9px 18px;
  border: 1px solid #5ce38f;
  height: 134px;
  display: block;

  &:active,
  &:hover,
  &:focus {
    outline: 0;
    outline-offset: 0;
  }
}

.general-contacts-text {
  font-size: 19px;
  font-weight: 300;
  color: #c3c3c3;
  letter-spacing: 0.96px;
}