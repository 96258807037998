.contacts-section {
  background: #1e1f1f;
  min-height: 618px;
  padding-top: 74px;
  padding-bottom: 100px;
}

.contacts-container {
  max-width: 925px;
  margin: 0 auto;

}

.contacts-wrap {
  display: flex;
  justify-content: space-between;

  &__titling {
    color: #ffffff;
    font-size: 13px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 1.2px;
  }
}

.feedback {
  width: 60%;

  &__titling {
    margin-bottom: 26px;
  }
}

.form {
  &__input {
    margin-bottom: 27px;
    background: #262626;
    color: #ffffff;

    &:active,
    &:hover,
    &:focus {
      background: #262626;
      color: #ffffff;
    }
  }



  &__message {
    margin-bottom: 32px;
    background: #262626;

    &:active,
    &:hover,
    &:focus {
      background: #262626;
      color: #ffffff;
    }
  }
}

.contacts-section {
  padding-bottom: 200px;
}

.contacts {
  width: 33%;
  display: flex;
  align-items: flex-end;

  &__titling {
    margin-bottom: 289px;
  }

  &-info {
    &__icon {
      padding-top: 2px;
      margin-right: 12px;

      img {
        width: 20px;
        height: 20px;
      }
    }

    &__item {
      display: flex;
    }

    &__mail {
      margin-bottom: 28px;
    }

    &__text-box {
      margin-bottom: 24.01px;
    }


  }
}

.map {
  height: 741px;
  width: 100%;
}