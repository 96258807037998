.slider-section {
  height: 920px;
  cursor: pointer;
  position: relative;

  .slick-initialized,
  .slick-list.draggable,
  .slick-track,
  .slick-current {
    height: 100%;
  }

  .slick-current div {
    height: 100%;
  }
}

.slide {

  width: 87px;
  min-height: 100%;
  background: inherit;
  position: absolute;
  right: 0;
  top: 0;
  box-shadow: 0 0 32px rgba(0, 0, 0, .2);
  position: absolute;

  &-top {
    position: absolute;
    width: 100%;
    height: 50%;
    background: inherit;
    background: rgba(0, 0, 0, .35);
    backdrop-filter: blur(12px);

    &__text {
      position: absolute;
      top: 45px;
      left: 33px;
      writing-mode: tb-rl;
      transform: rotate(360deg);
      color: #ffffff;
      font-family: "SFUIDisplay-Thin";
      font-size: 15px;
      font-weight: 400;
      letter-spacing: 0.16px;

      span.text1 {
        color: #ffffff;
        font-family: "SFUIDisplay-Heavy";
        font-size: 15px;
        font-weight: 400;
        letter-spacing: .7px;
      }
    }
  }

  &-bottom {
    position: absolute;
    width: 100%;
    height: 50%;
    bottom: 0;
    background: rgba(20, 23, 25, .25);
    backdrop-filter: blur(12px);

    &__count {
      position: absolute;
      top: -15px;
      left: 24px;
      color: #d0d0d0;
      font-family: 'Franklin';
      font-size: 27px;
      font-weight: 400;
    }
  }


  &__next {
    position: absolute;
    right: 33px;
    bottom: 58px;
  }

  &__next {
    cursor: pointer;
  }
}

.slider-mouse {
  display: block;
  position: absolute;
  bottom: 45px;
  left: 50%;
  margin-left: -12px;
}

.slick-dots {

  bottom: 20px;
}

.slick-active {
  background: #c11d1d !important;
}

.slick-dots li {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #ffffff;
}