.about {

  padding-top: 52px;
  background: #e9e9e9;
  display: flex;
  justify-content: center;
  padding-bottom: 68px;

}

.about-desc {
  max-width: 960px;
  width: 960 / 1920 *100%;
  text-align: center;

  &__text {
    color: #424242;
    font-size: 21, 67px;
    font-weight: 400;
    line-height: 26, 98px;
    font-family: 'SFUIDisplay-Light';

    &_heavy {
      color: #c11d1d;
      font-family: "SFUIDisplay-Heavy";
      text-transform: uppercase;
    }

    &_red {
      color: #c11d1d;
      letter-spacing: 0.96px;
    }
  }

}

.btn {
  display: block;
  margin: 0 auto;
  margin-top: 40px;
  width: 253px;
  height: 35px;
  border: 1.2px solid #c11d1d;

  color: #c11d1d !important;
  font-size: 19px;
  font-weight: 700;
  line-height: 24.01px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  img {
    position: absolute;
    right: 15px;
    top: 8.5px;
  }
}